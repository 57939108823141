.app-logo img {
    width: 100px;
}

.app-layout-sub-header {
    background: #fff;
    padding: 0;
}

.app-layout-content {
    flex: auto;
    min-height: 0;
}

.app-layout-background {
    background: #fff;
    padding: 24px;
    min-height: 500px;
}
