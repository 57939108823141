.calculation-image {
    text-align: right;
}
.calculation-image__img {
    max-width: 100%;
}

.calculation-result {
    padding: 16px;
    border: 2px solid #eee;
    border-radius: 8px;
}
